// Gatsby supports TypeScript natively!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

type Data = {
  site: {
    siteMetadata: {
      title: string
    }
  }
}

const BlogIndex = ({ data, location }: PageProps<Data>) => {
  const siteTitle = data.site.siteMetadata.title
  return (
    <Layout location={location} title={`About`}>
      <SEO title="About" />
<p>I am an Engineer, who plays around with Ruby on Rails, node.js, Java, Angular, and a little bit React in my professional life. Add to that the time spent working towards designing the application infrastructure, data flow, and others. Being a part of the Tech industry for 10+ years now, below is a short excerpt from my journey so far.
</p>
<p>
Rails had me mesmerized during my initial years and still excites me. The ease with which you can create and ship applications using the beautiful framework had put such blindfold that others would seem lackluster. Using this in conjunction with MySQL/PostgreSQL/MongoDB, and having the ease of deployments provided by Heroku, Capistrano et al were very exciting. Having the knowledge of Ruby also helped me to write recipes and cookbooks in Chef and orchestrate the deployments of ~20 servers including staging and production environments.</p>
<p>
Then a few years later came the huge wave, node.js . It was one of the bandwagons that I boarded, and embarked on the journey of “Everything JavaScript”. Angular, React, node.js, Backbone, require.js, Sails I’d seen and worked with all of them. I had used most of them them professionally, some, personally. I had seen the level of complexity that they could handle, as well as the ease in writing asynchronous programs. Something which I faced difficulties when using Rails.
</p>
<p>
I started with Java towards the end of 2019. 9 and a half years went by before that, I never moved an inch towards Java. After working with it for the better half of a year I started to realize why it is one of the leading languages that is selected by a huge number of developers across the globe. Java helped to actually understand the patterns that we learn academically and forget later. During this time I was writing Java based micro services which were powered by Amazon SQS in a Event Driven approach.
</p>
<p>
Presently, I try to mentor my team and make project estimations with a quantitative approach, using the past 10+ years of experience. The other day to day activities revolve around adhereing to the Agile practices and ensuring delivery. At the same time keeping the quality barrier at high standards across the board.
</p>
<hr />
<p>Outside of the usual work hours, I like to learn new skills and design patterns. I am huge fan of football(Soccer for my American friends :P) and Liverpool FC. I like to indulge in sporting activities, both indoors and outdoors; namely:
  <ul>
    <li>Football</li>
    <li>Cricket</li>
    <li>Badminton</li>
    <li>Table Tennis</li>
    <li>Chess</li>
    <li>and others..</li>
  </ul>
</p>

<p>Hit me up if you want to get connected. You can send me an email at <a href="mailto:rohit0981989@gmail.com">rohit0981989[at]gmail[dot]com</a>. My other networking sites are listed below, we can get in touch on those as well.</p>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
